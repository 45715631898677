import { useLanguageList } from 'Scenes/ExcludeLanguages/ExcludeLanguagesView';
import { AppliedFilters, SongGroup } from 'Scenes/SingASong/SongSelectionVirtualized/Hooks/useSongList';
import { SongPreview } from 'interfaces';
import { ReactElement, ReactNode, useMemo } from 'react';

export interface PlaylistEntry {
  name: string;
  display?: ReactNode;
  hideNew?: boolean;
  Wrapper?: (props: { children: ReactElement; focused: boolean; active: boolean }) => ReactNode;
  filters: AppliedFilters;
  groupData?: (song: SongPreview) => Pick<SongGroup, 'name' | 'displayShort' | 'displayLong'>;
  postGrouping?: (groups: SongGroup[]) => SongGroup[];
  sortingFn?: (a: SongPreview, b: SongPreview) => number;
}

export const usePlaylists = (songs: SongPreview[], recommended: string[], isLoading: boolean): PlaylistEntry[] => {
  const songLanguages = useLanguageList(songs);

  return useMemo<PlaylistEntry[]>(() => {
    if (isLoading) return [];

    const all: PlaylistEntry = { name: 'Songs', filters: {} };
    const playlists: Array<PlaylistEntry | null> = [
      all,
    ];

    return playlists.filter((playlist): playlist is PlaylistEntry => playlist !== null);
  }, [songLanguages, isLoading, recommended]);
};
