import styled from '@emotion/styled';
import { css } from '@emotion/react';
import CameraManager from 'Camera/CameraManager';
import { Button } from 'Elements/Button';
import { sumDetailedScore } from 'Scenes/Game/Singing/GameState/Helpers/calculateScore';
import { PlayerScore } from 'Scenes/Game/Singing/PostGame/PostGameView';
import CameraRoll from 'Scenes/Game/Singing/PostGame/Views/Results/CameraRoll';
import PlayerScoreView from 'Scenes/Game/Singing/PostGame/Views/Results/PlayerScore';
import useKeyboard from 'hooks/useKeyboard';
import useKeyboardHelp from 'hooks/useKeyboardHelp';
import { GAME_MODE, HighScoreEntity, SingSetup, Song } from 'interfaces';
import posthog from 'posthog-js';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  onNextStep: () => void;
  players: PlayerScore[];
  highScores: HighScoreEntity[];
  singSetup: SingSetup;
  song: Song;
}

function ResultsView({ onNextStep, players, highScores, singSetup, song }: Props) {
  // -1 so the animation starts from the first segment
  const [segment, setSegment] = useState<number>(-1);

  useEffect(() => {
    if (segment < 0) {
      setSegment(0);
    } else if (segment < 4) {
      const interval = setInterval(() => {
        setSegment((segment) => segment + 1);
      }, 1_500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [segment]);
  const isAnimFinished = segment > 3;

  const unlockStep = () => {
    if (isAnimFinished) {
      window.open('https://orcd.co/9ym2z5w', '_blank');
    } 
  };

  const nextStep = () => {
    if (isAnimFinished) {
      onNextStep();
    } 
  };

  useKeyboard(
    {
      accept: nextStep,
    },
    true,
    [segment],
  );
  const help = useMemo(
    () => ({
      accept: 'Next',
    }),
    [],
  );
  useKeyboardHelp(help, true);

  const isCoop = singSetup.mode === GAME_MODE.CO_OP;
  const finalPlayers = isCoop ? [{ ...players[0], name: players.map((player) => player.name).join(', ') }] : players;

  const playerScores = finalPlayers.map((player) => sumDetailedScore(player.detailedScore[0]));
  const highestScore = Math.max(...playerScores);

  const unlock = highestScore >= (0.5 * song.maxPoints);

  return (
    <>
      <ImageContainer>{unlock ? <Image src={`./ResultsWin.png`}/> : <Image src={`./ResultsLose.png`}/>}</ImageContainer> 
      <Container>
        <ScoresContainer>
          {finalPlayers.map((player, number) => (
            <PlayerScoreView
              playerNumber={player.playerNumber}
              useColors={!isCoop}
              revealHighScore={segment > 3}
              segment={segment}
              key={number}
              player={player}
              highScores={highScores}
              highestScore={highestScore}
              singSetup={singSetup}
              song={song}
            />
          ))}
        </ScoresContainer>
        <CenterButton>
          {isAnimFinished ?
            <ButtonContainer>
              <SongSelectionButton
                onClick={unlockStep}
                focused
                data-test={isAnimFinished ? 'highscores-button' : 'skip-animation-button'}>
                {unlock ? <Image src={`./btnWin.png`}/> : <BtnImage src={`./btnLose.png`}/>}
              </SongSelectionButton>
              <SongSelectionButton
                onClick={nextStep}
                focused
                data-test={isAnimFinished ? 'highscores-button' : 'skip-animation-button'}>
                <Image src={`./btnTryAgain.png`}/>
              </SongSelectionButton>
            </ButtonContainer>
            : null
          }
        </CenterButton>
        {/* {CameraManager.getPermissionStatus() && <StyledPhotoRoll />} */}
      </Container>
    </>
  );
}
const Container = styled.div`
  position: relative;
  padding: 20rem 15rem 10rem 15rem;
  top: 0;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ScoresContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 15rem;
  flex: 1;
`;

const CenterButton = styled.div`
  width: 100%;
  height: 4.5vh;
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

const SongSelectionButton = styled(Button)<{ focused: boolean }>`
  border-radius: 1rem;
  width: 40rem;
  font-size: 1.9vw;
  color: #4f8f98;
  font-family: 'Comic Sans MS', Seravek, 'Gill Sans Nova', Ubuntu, Calibri, 'DejaVu Sans', source-sans-pro, sans-serif;
`;


const StyledPhotoRoll = styled(CameraRoll)`
  position: absolute;
  top: calc(50% - 30rem);
  left: 95rem;
  transform: scale(0.75);
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0 3vh;
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  z-index: 1;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const BtnImage = styled.img`
  width: 80%;
  height: auto;
  z-index: 1;
`;

export default ResultsView;
